angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicQuizBox/nordicQuizBox.html',
    '<div class="quiz-box" style="position: relative">\n' +
    '    <div class="quiz-box__body">\n' +
    '        <div ng-repeat="c in components">\n' +
    '            <div component="c"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div ng-if="footerImage">\n' +
    '        <img class="quiz-box__footer" src="/image/nordic/{{ footerImage }}" alt="{{ footerImage }}">\n' +
    '\n' +
    '    </div>\n' +
    '            <p ng-if="footerImageText" style="text-align: center;\n' +
    '                                           color: #fff;\n' +
    '                                           position: absolute;\n' +
    '                                           margin-left: auto;\n' +
    '                                           margin-right: auto;\n' +
    '                                           left: 0;\n' +
    '                                           right: 0;\n' +
    '                                           bottom: 20px;">{{ :: footerImageText | translate }}</p>\n' +
    '</div>');
}]);
