angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/component/tabs/tabs.html',
    '<div style="margin-bottom: 15px;">\n' +
    '    <ul class="nav nav-tabs" >\n' +
    '        <li ng-repeat="tab in tabs"\n' +
    '            ng-class="{\'active\': $index == open}"\n' +
    '            role="presentation">\n' +
    '            <a ng-click="show($index)">{{ :: tab.headerTextId | translate }}</a>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '    <div class="tab-content" style="background-color: #fff">\n' +
    '        <div ng-repeat="tab in tabs" ng-class="{\'active\': $index == open}" role="tabpanel" class="tab-pane" style="padding: 15px; border: 1px solid #ddd; border-top: 0; border-radius: 5px; border-top-left-radius: 0;">\n' +
    '            <div ng-repeat="c in tab.components">\n' +
    '                <div component="c"></div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
