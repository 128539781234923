angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/advanceButton/advanceButton.html',
    '<div>\n' +
    '    <div class="text-center advance-button">\n' +
    '        <button class="btn btn-primary btn-lg btn-green" ng-click="advance()">\n' +
    '            {{ \'general.advance_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
