angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultScales/personalityResultScales.html',
    '<div>\n' +
    '    <div class="row">\n' +
    '        <div class="col-sm-12">\n' +
    '            <div class="personality-type text-center">\n' +
    '                <h1>{{ \'general.personality_type_header\' | translate }}</h1>\n' +
    '                <p>{{ \'general.personality_type_\' + trait | translate }}</p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-sm-6" ng-repeat="scale in scales">\n' +
    '            <div style="background-color: #eeeeee; padding: 10px; margin-bottom: 15px; margin-top: 15px">\n' +
    '                <div class="row">\n' +
    '                    <div class="col-xs-4 text-center scale-left">\n' +
    '                        <p class="trait"\n' +
    '                           data-toggle="tooltip"\n' +
    '                           data-placement="left"\n' +
    '                           title="{{ \'general.trait_\' + scale.lower + \'_info\' | translate }}"\n' +
    '                        >\n' +
    '                            <small><i class="fa fa-question-circle"></i></small>\n' +
    '                            {{ \'general.trait_\' + scale.lower | translate }}\n' +
    '                        </p>\n' +
    '                        <p\n' +
    '                                class="trait-percentage"\n' +
    '                                ng-class="scale.upperPercent < scale.lowerPercent ? \'majority\' : \'\'"\n' +
    '                        >{{ scale.lowerPercent }}%</p>\n' +
    '                    </div>\n' +
    '                    <div class="col-xs-4 text-center">\n' +
    '                        <div\n' +
    '                                class="personality-result-radial"\n' +
    '                                personality-result-radial-progress="{{ scale.dial < 0.5 ? 1 - scale.dial : scale.dial }}"\n' +
    '                                personality-result-radial-reverse="{{ scale.dial < 0.5 ? true : false }}"\n' +
    '                        ></div>\n' +
    '                    </div>\n' +
    '                    <div class="col-xs-4 text-center scale-right">\n' +
    '                        <p class="trait"\n' +
    '                           data-toggle="tooltip"\n' +
    '                           data-placement="left"\n' +
    '                           title="{{ \'general.trait_\' + scale.upper + \'_info\' | translate }}"\n' +
    '                        >\n' +
    '                            <small><i class="fa fa-question-circle"></i></small>\n' +
    '                            {{ \'general.trait_\' + scale.upper | translate }}\n' +
    '                        </p>\n' +
    '                        <p\n' +
    '                                class="trait-percentage"\n' +
    '                                ng-class="scale.upperPercent > scale.lowerPercent ? \'majority\' : \'\'"\n' +
    '                        >{{ scale.upperPercent }}%</p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
