angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicQuizBoxHeadingLogoAndSubText/nordicQuizBoxHeadingLogoAndSubText.html',
    '<div class="col-md-12 quiz-box_top_container">\n' +
    '   <div class="text-center col-md-12">\n' +
    '        <img class="quiz-box_top_image" src="/image/logo/{{logo}}"/>\n' +
    '    </div>\n' +
    '     <p class="text-center text-bold quiz-box_top_paragraph">{{ :: text | translate }}: </p>\n' +
    '\n' +
    '</div>');
}]);
