angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/questionTextChoice/questionTextChoice.html',
    '<div class="question-text-choice">\n' +
    '    <p class="question-text-choice-text">\n' +
    '        {{ ::question.textId | translate }}\n' +
    '    </p>\n' +
    '    <div class="col-sm-1"></div>\n' +
    '    <div class="panel panel-default col-sm-10 question-text-choice-listgroupwrapper">\n' +
    '        <div class="list-group">\n' +
    '            <a class="list-group-item question-text-choice-item"\n' +
    '               ng-repeat="choice in question.choices"\n' +
    '               ng-class="{\'active\': isSelected(choice)}"\n' +
    '               ng-click="select(choice)">\n' +
    '                {{ ::choice.textId | translate }}\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-1"></div>\n' +
    '</div>\n' +
    '');
}]);
