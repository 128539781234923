angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicResult/nordicResultButtonRestart/nordicResultButtonRestart.html',
    '<div>\n' +
    '    <button class="btn btn-lg btn-primary btn-block nordic-button-grey" onclick="document.location=\'/clear\';">\n' +
    '        <i class="fa fa-repeat"></i> {{ \'general.result_restart_button\' | translate }}\n' +
    '    </button>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
