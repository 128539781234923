angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicResult/nordicResultAnswerDistributionBar/nordicResultAnswerDistributionBar.html',
    '<div class="nordic-result-section-answer-distribution" ng-class="{ lg : size == \'large\', sm: size == \'small\' }">\n' +
    '\n' +
    '    <div class="progress">\n' +
    '\n' +
    '        <div class="progress-bar progress-bar-success"\n' +
    '             role="progressbar"\n' +
    '             aria-valuenow="{{ ::correctPercent }}"\n' +
    '             aria-valuemin="0"\n' +
    '             aria-valuemax="100"\n' +
    '             ng-style="{ \'width\' : correctPercent + \'%\' }"\n' +
    '             ng-hide="correctCount == 0">\n' +
    '            {{ ::correctCount }} {{ \'general.section_result_correct\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="progress-bar progress-bar-warning"\n' +
    '             role="progressbar"\n' +
    '             aria-valuenow="{{ ::incorrectPercent }}"\n' +
    '             aria-valuemin="0"\n' +
    '             aria-valuemax="100"\n' +
    '             ng-style="{ \'width\' : incorrectPercent + \'%\' }"\n' +
    '             ng-hide="incorrectCount == 0">\n' +
    '            {{ ::incorrectCount }} {{ \'general.section_result_incorrect\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="progress-bar progress-bar-danger"\n' +
    '             role="progressbar"\n' +
    '             aria-valuenow="{{ ::unansweredPercent }}"\n' +
    '             aria-valuemin="0"\n' +
    '             aria-valuemax="100"\n' +
    '             ng-style="{ \'width\' : unansweredPercent + \'%\' }"\n' +
    '             ng-hide="unansweredCount == 0">\n' +
    '            {{ ::unansweredCount }} {{ \'general.section_result_unanswered\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
