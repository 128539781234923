angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicCategoryList/nordicCategoryList.html',
    '<div class="category">\n' +
    '    <div class="category__items">\n' +
    '        <div ng-repeat="c in components">\n' +
    '            <div component="c"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
