angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('section/quiz/template/loader.html',
    '<div style="margin-top: 10px; margin-bottom: 10px; padding-top: 200px; padding-bottom: 200px">\n' +
    '\n' +
    '    <div ng-show="!error">\n' +
    '        <div class="text-center">\n' +
    '            <h1><i class="fa fa-circle-o-notch fa-spin"></i></h1>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-show="error">\n' +
    '        <div class="text-center">\n' +
    '            <p>{{ error }}</p>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>');
}]);
