angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/iq/component/iqQuestionVisualMatrix/iqQuestionVisualMatrix.html',
    '<div class="visual-matrix">\n' +
    '    <div class="visual-matrix-image">\n' +
    '        <img ng-src="{{ question.image }}"/>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="visual-matrix-choices">\n' +
    '        <a ng-repeat="choice in question.choices"\n' +
    '            ng-click="select(choice)"\n' +
    '            ng-class="{\'active\': isSelected(choice)}"\n' +
    '            class="visual-matrix-choice"\n' +
    '            style="">\n' +
    '            <img ng-src="{{ choice.image }}"/>\n' +
    '        </a>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
