angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/advanceIfCorrectButton/advanceIfCorrectButton.html',
    '<div>\n' +
    '    <div class="text-center">\n' +
    '        <div ng-show="state() == \'waiting\'">\n' +
    '            <button class="btn btn-lg btn-primary btn-green" ng-disabled="true">\n' +
    '                {{ \'general.advance_correct_button_waiting\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '        <div ng-show="state() == \'incorrect\'">\n' +
    '            <button class="btn btn-lg btn-warning" ng-disabled="true">\n' +
    '                {{ \'general.advance_correct_button_incorrect\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '        <div ng-show="state() == \'correct\'">\n' +
    '            <button class="btn btn-lg btn-success btn-green" ng-click="advance()">\n' +
    '                {{ \'general.advance_correct_button_correct\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
