angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/progressBar/progressBar.html',
    '<div>\n' +
    '<div class="progress" style="height: 3px; background-color: #999999; margin-bottom: -5px;">\n' +
    '    <div class="progress-bar"\n' +
    '         role="progressbar"\n' +
    '         aria-valuenow="{{ ::percentage }}"\n' +
    '         aria-valuemin="0"\n' +
    '         aria-valuemax="100"\n' +
    '         ng-style="{ \'width\' : percentage + \'%\' }"\n' +
    '         style="background-color: rgb(44,49,48);"\n' +
    '    >\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="progress-bar-indicator" ng-style="{ \'padding-left\' : percentage + \'%\' }">\n' +
    '    <div style="margin-left: -7px;">\n' +
    '        <i class="fa fa-lg fa-caret-up"></i></br>\n' +
    '        <p> {{ :: percentage }} %</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '</div>');
}]);
