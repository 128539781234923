angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityStatisticsParticipation/personalityStatisticsParticipation.html',
    '<div class="personality-statistics-participation">\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '           <div class="personality-statistics-participation-header">\n' +
    '                {{ \'general.statistics_participation_header\' | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-6">\n' +
    '            <div class="personality-statistics-participation-counter">\n' +
    '                {{ flows }}\n' +
    '            </div>\n' +
    '            <div class="personality-statistics-participation-description">\n' +
    '                {{ \'general.participation_statistics_flows_text\' | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6">\n' +
    '           <div class="personality-statistics-participation-counter">\n' +
    '                {{ answers }}\n' +
    '            </div>\n' +
    '            <div class="personality-statistics-participation-description">\n' +
    '                {{ \'general.participation_statistics_answers_text\' | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
