angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultCharacterLike/personalityResultCharacterLike.html',
    '<div>\n' +
    '    <div class="personality-statistics-personalities">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '                <div class="personality-statistics-personalities-header">\n' +
    '                    {{ \'general.result_also_like\' | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-init="limit = 3">\n' +
    '            <div ng-repeat="match in matches | limitTo: limit as results">\n' +
    '                <div class="clearfix" ng-if="$index % 3 == 0"></div>\n' +
    '                <div class="col-sm-4 col-xs-4 personality-statistics-personalities-personality">\n' +
    '                    <img class="image" ng-src="{{ match.character.image }}" ng-click="textShow($index)">\n' +
    '                    <p class="name hidden-xs" ng-click="textShow($index)">{{ subtype == \'job\' ? match.character.namelocalId : match.character.nameId | translate }}</p>\n' +
    '                    <p class="name visible-xs" ng-click="textShow($index)" ng-bind-html="subtype == \'job\' ? match.character.namemobileId : match.character.nameId | translate | markdownBR"></p>\n' +
    '                    <hr/>\n' +
    '                    <p class="percent" ng-click="textShow($index)">{{ match.percent }}%</p>\n' +
    '                    <hr/>\n' +
    '                    <a\n' +
    '                            class="read-more"\n' +
    '                            ng-class="textIsShowing($index) ? \'active\' : \'\'"\n' +
    '                            ng-click="textShow($index)"\n' +
    '                    >\n' +
    '                        {{ \'general.character_read_more\' | translate }}\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '                <div\n' +
    '                        class="personality-statistics-personalities-personality-text"\n' +
    '                        ng-if="$index % 3 == 2 || $index >= matches.length - 1 "\n' +
    '                >\n' +
    '                    <div class="clearfix"></div>\n' +
    '                    <div class="personality-text" ng-if="$index % 3 != 1" ng-show="textIsShowing($index-2)">\n' +
    '                        <p><strong>\n' +
    '                            {{ matches[$index-2].character.shorttextId | translate}}\n' +
    '                        </strong></p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ matches[$index-2].character.knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="matches[$index-2].character.textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                    <div class="personality-text" ng-if="$index % 3 != 0" ng-show="textIsShowing($index-1)">\n' +
    '                        <p><strong>\n' +
    '                            {{ matches[$index-1].character.shorttextId | translate}}\n' +
    '                        </strong></p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ matches[$index-1].character.knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="matches[$index-1].character.textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                    <div class="personality-text"   ng-show="textIsShowing($index-0)">\n' +
    '                        <p><strong>\n' +
    '                            {{ matches[$index-0].character.shorttextId | translate}}\n' +
    '                        </strong></p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ matches[$index-0].character.knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="matches[$index-0].character.textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '                <button\n' +
    '                        class="btn btn-lg btn-personality-yellow"\n' +
    '                        ng-hide="results.length >= matches.length"\n' +
    '                        ng-click="limit = limit + 3"\n' +
    '                >\n' +
    '                    {{ \'general.button_show_more\' | translate }}\n' +
    '                </button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
