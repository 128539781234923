angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/paywall/paywall.html',
    '<div class="paywall">\n' +
    '\n' +
    '    <div class="paywall-magazines" ng-if="showContest == true">\n' +
    '        <img src="/image/personality/magazines_{{ lang }}.png"/>\n' +
    '    </div>\n' +
    '    <div class="paywall-header" ng-if="!removeFacebookButton">\n' +
    '        <button class="btn btn-lg btn-facebook" ng-click="loginFacebook()">\n' +
    '            <i class="fa fa-facebook-square"></i>\n' +
    '            {{ \'general.paywall_register_with_facebook_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div class="paywall-form">\n' +
    '        <form id="userForm" name="userForm" ng-submit="submitForm(userForm.$valid, userForm)" novalidate>\n' +
    '            <div class="row" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-6">\n' +
    '                    <div class="form-group"\n' +
    '                         ng-class="{ \'has-error\' : userForm.firstName.$invalid && !userForm.firstName.$pristine}">\n' +
    '                        <input type="text" name="firstName" class="form-control" autofocus\n' +
    '                               placeholder="{{ \'general.paywall_field_first_name_label\' | translate }}"\n' +
    '                               ng-model="firstName" ng-pattern="/^[A-z\\,\\.\\\'\\-æøåöäçÆØÅÖÄÇ\\n\\s\\t]+$/"\n' +
    '                               required\n' +
    '                        >\n' +
    '                    </div>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                            <span ng-show="userForm.firstName.$invalid && !userForm.firstName.$pristine"\n' +
    '                                  class="text-danger">\n' +
    '                                {{ \'general.invalid_first_name\' | translate }}\n' +
    '                            </span>\n' +
    '                        <span ng-show="userForm.firstName.length == 0" class="text-danger">\n' +
    '                                {{ \'general.field_required\' | translate }}\n' +
    '                            </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="col-md-6">\n' +
    '                    <div class="form-group"\n' +
    '                         ng-class="{ \'has-error\' : userForm.lastName.$invalid && !userForm.lastName.$pristine}">\n' +
    '                        <input type="text" class="form-control" name="lastName"\n' +
    '                               ng-pattern="/^[A-z\\,\\.\\\'\\-æøåöäçÆØÅÖÄÇ\\n\\s\\t]+$/"\n' +
    '                               placeholder="{{ \'general.paywall_field_last_name_label\' | translate }}"\n' +
    '                               ng-model="lastName"\n' +
    '                               required>\n' +
    '                    </div>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="userForm.lastName.$invalid && !userForm.lastName.$pristine" class="text-danger">\n' +
    '                            {{ \'general.invalid_last_name\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span ng-show="userForm.lastName.length == 0" class="text-danger">\n' +
    '                            {{ \'general.field_required\' | translate }}\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-6">\n' +
    '                    <div class="form-group"\n' +
    '                         ng-class="{ \'has-error\' : userForm.email.$invalid && !userForm.email.$pristine}">\n' +
    '                        <input type="email" name="email" class="form-control"\n' +
    '                               placeholder="{{ \'general.paywall_field_email_label\' | translate }}"\n' +
    '                               ng-model="email"\n' +
    '                               ng-pattern=\'/^[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]+\\@+[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]{2,}\\.[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]{2,}$/i\'\n' +
    '                               required>\n' +
    '                    </div>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                                    <span ng-show="!userForm.email.$pristine && userForm.email.$error.required"\n' +
    '                                          class="text-danger">\n' +
    '                                            {{ \'general.field_required\' | translate }}\n' +
    '                                    </span>\n' +
    '                        <span ng-show="!userForm.email.$pristine && userForm.email.$invalid" class="text-danger">\n' +
    '                                            {{ \'general.invalid_email\' | translate }}\n' +
    '                                    </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="col-md-6">\n' +
    '                    <div class="form-group"\n' +
    '                         ng-class="{ \'has-error\' : userForm.phoneNumber.$invalid && !userForm.phoneNumber.$pristine}">\n' +
    '                        <input type="text" name="phoneNumber" class="form-control"\n' +
    '                               placeholder="{{ \'general.paywall_field_phone_number_label\' | translate }}"\n' +
    '                               ng-model="phoneNumber" ng-pattern="/^\\+?[\\d\\-]{7,}$/"\n' +
    '                               required>\n' +
    '                    </div>\n' +
    '                    <div ng-show="facebookPhoneReq" class="text-danger">\n' +
    '                        {{ \'general.paywall_facebook_phonenumber_required\' | translate }}\n' +
    '                    </div>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                                            <span ng-show="userForm.phoneNumber.$viewValue.length == 0 && !userForm.phoneNumber.$pristine"\n' +
    '                                                  class="text-danger">\n' +
    '                                                    {{ \'general.field_required\' | translate }}\n' +
    '                                            </span>\n' +
    '                        <span ng-show="userForm.phoneNumber.$invalid && !userForm.phoneNumber.$pristine"\n' +
    '                              class="text-danger">\n' +
    '                                                    {{ \'general.invalid_phone\' | translate }}\n' +
    '                                            </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="margin-bottom: 15px" ng-if="quizType == \'iq_test\'">\n' +
    '                <div class="col-md-6">\n' +
    '                    <div class="form-group"\n' +
    '                         ng-class="{ \'has-error\' : userForm.birth.$invalid && !userForm.birth.$pristine}">\n' +
    '\n' +
    '                            <input type="text" class="form-control" name="birth"\n' +
    '                                   placeholder="{{ \'general.paywall_field_birth_label\' | translate }}"\n' +
    '                                   onfocus="(this.type=\'date\')"\n' +
    '                                   onblur="(this.type=\'text\')"\n' +
    '                                   ng-model="birth"\n' +
    '                                   id="birth"\n' +
    '                                   required\n' +
    '                                   ng-change="birthFix(this.birth)"\n' +
    '                            >\n' +
    '                    </div>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="variant == 1" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-12 paywall-permission-text">\n' +
    '                    <div ng-bind-html="\'general.before_checkbox\' | translate | markdown"></div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-12">\n' +
    '                    <label for="accept">\n' +
    '                        <input type="checkbox"\n' +
    '                               name="accept"\n' +
    '                               value="true"\n' +
    '                               ng-model="accept"\n' +
    '                               id="accept"\n' +
    '                               required>\n' +
    '                        {{ \'general.paywall_field_accept_label\' + variant | translate }}\n' +
    '                    </label>\n' +
    '                    <span ng-hide="accept" ng-if="quizType == \'personality\' || quizType == \'career\'"\n' +
    '                          class="text-bold text-danger">{{ \'general.paywall_field_accept_label_error\' | translate }}</span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="variant != 2" class="row" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-12 paywall-permission-text">\n' +
    '                    {{ variantTranslate }}\n' +
    '                    <div ng-hide="open">\n' +
    '                        <div ng-bind-html="\'general.paywall_permission_text_variant_\' + variant | translate | markdown"></div>\n' +
    '                        <div class="text-center">\n' +
    '                            <a ng-click="permissionText = !permissionText">\n' +
    '                                <i class="fa fa-chevron-down"></i>{{ \'general.paywall_permission_read_more_button\' |\n' +
    '                                translate }}\n' +
    '                            </a>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!-- Split test variants -->\n' +
    '                    <div ng-show="permissionText"\n' +
    '                         ng-bind-html="\'general.paywall_permission_text_markdown\' + variant | translate | markdown"\n' +
    '                    >\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="col-md-12 paywall-permission-text" style="margin-top: 10px" ng-if="showContest == true">\n' +
    '                    {{ \'general.paywall_competition_first_sentence_text\' | translate}}\n' +
    '                    <div ng-hide="open">\n' +
    '                        <div class="text-center">\n' +
    '                            <a ng-click="competitionText = !competitionText">\n' +
    '                                <i class="fa fa-chevron-down"></i>{{ \'general.paywall_permission_read_more_button\' |\n' +
    '                                translate }}\n' +
    '                            </a>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <!-- Split test variants -->\n' +
    '                    <div ng-show="competitionText"\n' +
    '                         ng-bind-html="\'general.paywall_competition_detailed_text\' | translate"\n' +
    '                    >\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="variant == 2" style="margin-bottom: 15px">\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-12 paywall-permission-text">\n' +
    '                        <div class="col-md-1 col-xs-2">\n' +
    '                            <i class="fa fa-3x fa-check"></i>\n' +
    '                        </div>\n' +
    '                        <div class="col-md-11 col-xs-10">\n' +
    '                            {{ variantTranslate }}\n' +
    '                            <div ng-hide="open">\n' +
    '                                <div ng-bind-html="\'general.paywall_permission_text_variant_\' + variant | translate | markdown"></div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="text-center">\n' +
    '                            <a ng-click="permissionText = !permissionText">\n' +
    '                                <i class="fa fa-chevron-down"></i>{{ \'general.paywall_permission_read_more_button\' |\n' +
    '                                translate }}\n' +
    '                            </a>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div ng-show="permissionText"\n' +
    '                             ng-bind-html="\'general.paywall_permission_text_markdown\' + variant | translate | markdown"\n' +
    '                        >\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <br/>\n' +
    '\n' +
    '                <div class="row">\n' +
    '                    <div class="col-md-12 paywall-permission-text">\n' +
    '                        <div class="col-md-1 col-xs-2">\n' +
    '                            <i class="fa fa-3x fa-check"></i>\n' +
    '                        </div>\n' +
    '                        <div class="col-md-11 col-xs-10">\n' +
    '                            <p ng-bind-html="\'general.global_interest\' | translate | markdown"></p>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="text-center">\n' +
    '                            <a ng-click="globalInterest = !globalInterest">\n' +
    '                                <i class="fa fa-chevron-down"></i>{{ \'general.paywall_permission_read_more_button\' |\n' +
    '                                translate }}\n' +
    '                            </a>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <p ng-bind-html="\'general.global_interest_show_more\' | translate | markdown"\n' +
    '                           ng-show="globalInterest"></p>\n' +
    '                        <br>\n' +
    '\n' +
    '                        <div class="col-md-11 col-xs-10 col-md-offset-1 col-xs-offset-2">\n' +
    '                            <p ng-bind-html="\'general.choose_interests\' | translate | markdown"></p>\n' +
    '                            <span style="color:red" ng-show="!isValidCheckboxes()">{{ \'general.choose_interests_tip\' |\n' +
    '                                translate }}</span>\n' +
    '                            <div ng-repeat="(key, interestName) in interests">\n' +
    '                                <p>\n' +
    '                                    <input type="checkbox" ng-model="userInterests[interestName]"\n' +
    '                                           name="interests[{{ interestName }}]"/>\n' +
    '                                    {{ \'general.\' + interestName | translate }}\n' +
    '                                    <a ng-click="showMore = !showMore">\n' +
    '                                        <i class="fa fa-chevron-down"></i>\n' +
    '                                        {{ \'general.paywall_permission_read_more_button\' | translate }}\n' +
    '                                    </a>\n' +
    '                                </p>\n' +
    '                                <p ng-show="showMore">\n' +
    '                                    <em>{{ \'general.show_more_\' + interestName | translate }}</em>\n' +
    '                                </p>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div ng-repeat="(key, interestName) in hiddenInterests" ng-show="hiddenCheckboxes">\n' +
    '                                <p>\n' +
    '                                    <input type="checkbox" ng-model="userInterests[interestName]"\n' +
    '                                           name="interests[{{ interestName }}]"/>\n' +
    '                                    {{ \'general.\' + interestName | translate }}\n' +
    '                                    <a ng-click="showMore = !showMore">\n' +
    '                                        <i class="fa fa-chevron-down"></i>\n' +
    '                                        {{ \'general.paywall_permission_read_more_button\' | translate }}\n' +
    '                                    </a>\n' +
    '                                </p>\n' +
    '                                <p ng-show="showMore">\n' +
    '                                    <em>{{ \'general.show_more_\' + interestName | translate }}</em>\n' +
    '                                </p>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <div class="text-center">\n' +
    '                            <a ng-click="hiddenCheckboxes = !hiddenCheckboxes">\n' +
    '                                <i class="fa fa-chevron-down"></i>{{ \'general.paywall_hidden_checkboxes\' | translate\n' +
    '                                }}\n' +
    '                            </a>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="row" style="margin-bottom: 15px">\n' +
    '                <div class="col-md-12">\n' +
    '                    <button type="submit"\n' +
    '                            ng-disabled="!accept || userForm.$invalid"\n' +
    '                            class="btn btn-block btn-primary {{ accept && userForm.$valid ? \'btn-green\' : \'\' }}">{{\n' +
    '                        \'general.paywall_submit\' | translate }}\n' +
    '                    </button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="row" style="margin-bottom: 15px" ng-show="debug">\n' +
    '                <div class="col-md-12">\n' +
    '                    <a class="btn btn-xs btn-block btn-danger" ng-click="debugFill()">DEBUG Fill form</a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
