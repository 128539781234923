angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityProgress/personalityProgress.html',
    '<div class="progress progress-personality">\n' +
    '    <span class="progress-value">{{ textId | translate:textData }}</span>\n' +
    '    <div class="progress-bar"\n' +
    '        role="progressbar"\n' +
    '        aria-valuenow="{{ ::percentage }}"\n' +
    '        aria-valuemin="0"\n' +
    '        aria-valuemax="100"\n' +
    '        ng-style="{ \'width\' : percentage + \'%\' }">\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
