angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicResult/nordicResultDistribution/nordicResultDistribution.html',
    '<div class="text-center nordic-result-distribution">\n' +
    '    <h4>{{ :: header | translate }}</h4>\n' +
    '    <p></p>\n' +
    '    <p>{{ :: \'general.result_distribution_percentile\' | translate:{percentile: percentile} }}</p>\n' +
    '    <div class="nordic-result-curve-distribution-curve">\n' +
    '        <img src="/image/nordic/bellcurve.svg"/>\n' +
    '    </div>\n' +
    '    <div class="nordic-result-curve-distribution-resultbox" ng-style="{ \'margin-left\' : (result-100)*7+\'px\' }">\n' +
    '        <i class="fa fa-lg fa-caret-up"></i></br>\n' +
    '        <p class="nordic-result-curve-distribution-result">{{ :: result }}</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
