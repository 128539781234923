angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/iq/component/iqResult/iqResultSection/iqResultSection.html',
    '<div class="iq-result-section ">\n' +
    '    <div class="text-center">\n' +
    '        <h4>{{ :: header | translate }}</h4>\n' +
    '    </div>\n' +
    '    <div style="font-size: 12px" ng-bind-html="text | translate | markdown"></div>\n' +
    '    <div class="iq-result-section-plot">\n' +
    '        <img src="/image/iq/plot-{{ scale }}.svg" />\n' +
    '    </div>\n' +
    '    <div style="margin-left: 160px;" ng-style="{ \'margin-top\' : -35 - (scale * 5)  + \'px\'}">\n' +
    '        {{ :: \'general.result_score_section_yourscore\' | translate }} {{ :: scale }}\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
