angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultStatistics/personalityResultStatistics.html',
    '<div class="personality-statistics-participation">\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-12">\n' +
    '            <div class="personality-statistics-participation-header">\n' +
    '                {{ \'general.result_statistics_header\' | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-6">\n' +
    '            <div class="personality-statistics-participation-counter">\n' +
    '                {{ characters }} {{ \'general.result_same_characters_header\' | translate }}\n' +
    '            </div>\n' +
    '            <div class="personality-statistics-participation-description">\n' +
    '                {{ result_same_characters }}\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-xs-6">\n' +
    '            <div class="personality-statistics-participation-counter">\n' +
    '                {{ 100 *    traits / flows | number:2 }} %\n' +
    '            </div>\n' +
    '            <div class="personality-statistics-participation-description">\n' +
    '                {{ \'general.result_same_traits\' | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
