angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultCharacterJob/personalityResultCharacterJob.html',
    '<div>\n' +
    '    <div ng-if="lang === \'da\' || lang === \'nb\'" class="job-text personality-character-intro">\n' +
    '        <p><strong>{{ \'general.pre_result_text\' | translate }}</strong></p>\n' +
    '    </div>\n' +
    '\n' +
    '    <div style="margin-top: 20px;">\n' +
    '        <h2 class="">{{ \'general.result\' | translate }}</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div>\n' +
    '        <div class="row job-result-top">\n' +
    '            <div class="col-md-6 col-xs-12 job-image">\n' +
    '                <img class="" ng-src="{{ match.character.image_job_large }}">\n' +
    '            </div>\n' +
    '            <div class="col-md-6 col-xs-12 job-match text-center" >\n' +
    '                <div class="job-traits">\n' +
    '                    <h2 class="">{{ match.character.namelocalId | translate }}</h2>\n' +
    '                    <p class="job-traits-header">{{ \'general.your_character_trait\'| translate }}</p>\n' +
    '                    <p ng-bind-html="match.character.traitsId | translate | markdown"></p>\n' +
    '                </div>\n' +
    '\n' +
    '                <!--<div class="job-match-row2">-->\n' +
    '                    <div class="job-result-match">\n' +
    '                     {{ \'general.matched_character_percent\'| translate }}<br>\n' +
    '                    <span class="job-result-percent">{{ match.percent }}%</span>\n' +
    '                    </div>\n' +
    '\n' +
    '                <!--</div>-->\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="job-result-share">\n' +
    '        <button class="btn btn-facebook" ng-click="share()">\n' +
    '            <i class="fa fa-facebook-square"></i> {{ \'general.share_facebook_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div>\n' +
    '        <h2 class="job-result-title">{{ \'general.result_you_are_a\' | translate }} {{ subtype == \'job\' ? match.character.namelocalId : match.character.nameId | translate }}</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="personality-character text-center" style="margin-bottom: 15px">\n' +
    '        <div class="job-text personality-character-intro">\n' +
    '            <p><strong>{{ match.character.shorttextId | translate}}</strong></p>\n' +
    '        </div>\n' +
    '        <div\n' +
    '                class="personality-text"\n' +
    '                ng-bind-html="match.character.textId | translate | markdown"\n' +
    '        ></div>\n' +
    '        <!--<a ng-href="{{ match.character.linkId | translate }}" target="_blank" class="btn btn-personality-red">-->\n' +
    '            <!--{{ \'general.character_read_more\'| translate }}-->\n' +
    '        <!--</a>-->\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
