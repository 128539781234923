angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('section/quiz/template/page.html',
    '<div id="page-top">\n' +
    '    <div style="margin-top: 10px; margin-bottom: 10px; min-height: 600px">\n' +
    '        <div ng-repeat="c in page.components">\n' +
    '            <div component="c"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<!--<div ng-include="\'section/quiz/template/debugger.html\'"></div>-->\n' +
    '\n' +
    '');
}]);
