angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicResult/nordicResultButtonSave/nordicResultButtonSave.html',
    '<div>\n' +
    '    <div ng-show="state == \'pending\'">\n' +
    '        <button class="btn btn-lg btn-primary btn-block nordic-button-grey" ng-click="save()">\n' +
    '            <i class="fa fa-envelope"></i> {{ \'general.result_save_pending_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div ng-show="state == \'saving\'">\n' +
    '        <button class="btn btn-lg btn-default btn-block nordic-button-grey" ng-disabled="true">\n' +
    '            <i class="fa fa-circle-o-notch fa-spin"></i>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div ng-show="state == \'error\'">\n' +
    '        <button class="btn btn-lg btn-warning btn-block nordic-button-grey" ng-click="save()">\n' +
    '            <i class="fa fa-envelope"></i> {{ \'general.result_save_error_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <div ng-show="state == \'saved\'">\n' +
    '        <button class="btn btn-lg btn-success btn-disabled btn-block nordic-button-grey" ng-disabled="true">\n' +
    '            <i class="fa fa-envelope"></i> {{ \'general.result_save_saved_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
