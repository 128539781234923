angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityStatisticsPersonalities/personalityStatisticsPersonalities.html',
    '<div>\n' +
    '    <div class="personality-statistics-personalities">\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '                <div class="personality-statistics-personalities-header">\n' +
    '                    {{ \'general.statistics_personalities_header\' | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row" ng-init="limit = 3">\n' +
    '            <div ng-repeat="character in characters | limitTo: limit as results">\n' +
    '                <div class="clearfix" ng-if="$index % 3 == 0"></div>\n' +
    '                <div class="col-sm-4 col-xs-4 personality-statistics-personalities-personality">\n' +
    '                    <img class="image" ng-src="{{ character.image }}" ng-click="textShow($index)">\n' +
    '                    <p class="name hidden-xs" ng-click="textShow($index)">{{ subtype == \'job\' ? character.namelocalId : character.nameId | translate }}</p>\n' +
    '                    <p class="name visible-xs" ng-click="textShow($index)" ng-bind-html="subtype == \'job\' ? character.namemobileId : character.nameId | translate | markdownBR"></p>\n' +
    '                    <hr/>\n' +
    '                    <p class="percent" ng-click="textShow($index)">{{ character.percentage }}%</p>\n' +
    '                    <hr/>\n' +
    '                    <a\n' +
    '                            class="read-more"\n' +
    '                            ng-class="textIsShowing($index) ? \'active\' : \'\'"\n' +
    '                            ng-click="textShow($index)"\n' +
    '                    >{{ \'general.character_read_more\' | translate }}</a>\n' +
    '                </div>\n' +
    '                <div\n' +
    '                        class="personality-statistics-personalities-personality-text"\n' +
    '                        ng-if="$index % 3 == 2 || $index >= characters.length - 1 "\n' +
    '                >\n' +
    '                    <div class="clearfix"></div>\n' +
    '                    <div class="personality-text" ng-if="$index % 3 != 1" ng-show="textIsShowing($index-2)">\n' +
    '                        <p>\n' +
    '                            <strong>{{ characters[$index-2].shorttextId | translate}}</strong>\n' +
    '                        </p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ characters[$index-2].knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="characters[$index-2].textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                    <div\n' +
    '                            class="personality-text"\n' +
    '                            ng-if="$index % 3 != 0"\n' +
    '                            ng-show="textIsShowing($index-1)"\n' +
    '                    >\n' +
    '                        <p>\n' +
    '                            <strong>{{ characters[$index-1].shorttextId | translate}}</strong>\n' +
    '                        </p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ characters[$index-1].knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="characters[$index-1].textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                    <div class="personality-text"   ng-show="textIsShowing($index-0)">\n' +
    '                        <p><strong>{{ characters[$index-0].shorttextId | translate}}</strong></p>\n' +
    '                        <p class="known_for">\n' +
    '                            <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                            {{ characters[$index-0].knownforId | translate}}\n' +
    '                        </p>\n' +
    '                        <p ng-bind-html="characters[$index-0].textId | translate:data | markdown"></p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="row">\n' +
    '            <div class="col-xs-12">\n' +
    '                <button\n' +
    '                        class="btn btn-lg btn-personality-yellow"\n' +
    '                        ng-hide="results.length >= characters.length"\n' +
    '                        ng-click="limit = limit + 3"\n' +
    '                >\n' +
    '                    {{ \'general.button_show_more\' | translate }}\n' +
    '                </button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-xs-12 personality-profile-teaser">\n' +
    '\n' +
    '            <h3 class="profile-header">{{ \'general.profile_teaser_title\' | translate }}</h3>\n' +
    '            <p class="profile-name">{{ \'general.profile_teaser_name\' | translate }}</p>\n' +
    '            <p class="profile-text">&nbsp;</p>\n' +
    '                <button class="btn btn-lg btn-personality-yellow center" ng-click="navigator.next()">\n' +
    '                {{ \'general.start_quiz_button\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
