angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/advanceIfAnsweredButton/advanceIfAnsweredButton.html',
    '<div>\n' +
    '    <div class="text-center">\n' +
    '        <button class="btn btn-primary btn-lg btn-green"\n' +
    '                ng-disabled="!canAdvance()"\n' +
    '                ng-click="advance()">\n' +
    '                {{ \'general.advance_if_answered_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
