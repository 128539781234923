angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityCarousel/personalityCarousel.html',
    '<div class="personality-carousel">\n' +
    '    <div class="personality-carousel-marker">\n' +
    '        <div class="personality-carousel-marker-content" ng-if="subtype==\'job\'">\n' +
    '            <div class="personality-carousel-marker-number" ng-if="general.carousel_marker_number">{{ \'general.carousel_marker_number\' | translate }}</div>\n' +
    '            <div class="personality-carousel-marker-text">{{ \'general.carousel_marker_text\' | translate }}</div>\n' +
    '        </div>\n' +
    '        <div class="personality-carousel-marker-content" ng-if="subtype!=\'job\'">\n' +
    '            <div class="personality-carousel-marker-number">{{ \'general.carousel_marker_number\' | translate }}</div>\n' +
    '            <div class="personality-carousel-marker-text">{{ \'general.carousel_marker_text\' | translate }}</div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="personality-carousel-top">\n' +
    '        <h1>{{ \'general.carousel_header\' | translate }}</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="personality-carousel-center">\n' +
    '        <h2>{{ \'general.carousel_text\' | translate }}</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="personality-carousel-bottom">\n' +
    '        <div class="personality-carousel-pre-c2a" ng-if="lang === \'da\' || lang === \'nb\'">\n' +
    '            {{ \'general.pre_c2a_text\' | translate }}\n' +
    '        </div>\n' +
    '\n' +
    '        <button class="btn btn-lg btn-primary" ng-click="navigator.next()" ng-if="subtype==\'job\'">\n' +
    '            {{ \'general.start_quiz_button\' | translate }}\n' +
    '        </button>\n' +
    '        <button class="btn btn-lg btn-personality-red" ng-click="navigator.next()" ng-if="subtype!=\'job\'">\n' +
    '            {{ \'general.start_quiz_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="personality-carousel-sub text-center">\n' +
    '        <p>{{ \'general.carousel_text_sub\' | translate }}</p>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
