angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicProfile/nordicProfile.html',
    '<div class="profile-form" ng-app="">\n' +
    '    <div class="web-form js-web-form">\n' +
    '        <form name="profile" novalidate="novalidate">\n' +
    '            <div class="form-group" ng-class="{\'has-success\': !profile.name.$error}">\n' +
    '                <div class="form-item js-form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.name\' | translate }}</label>\n' +
    '                    <input type="text" ng-model="profile.name_value" class="form-input" name="name"\n' +
    '                           id="fb-replace-user-name" required="required" placeholder="\n' +
    '                        {{ :: \'general.name_placeholder\' | translate }}" ng-pattern="/^[A-z\\,\\.\\\'\\-æøåöäçÆØÅÖÄÇ\\n\\s\\t]+$/">\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.name.$touched && profile.name.$error.required">{{ \'general.field_required\' | translate }}</span>\n' +
    '                        <span ng-show="profile.name.$touched && profile.name.$invalid">{{ \'general.invalid_first_name\' | translate }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="form-item js-form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.surname\' | translate }}</label>\n' +
    '                    <input type="text" ng-model="profile.last_name_value" class="form-input" id="fb-replace-last-name"\n' +
    '                           name="last_name" required="required" placeholder="\n' +
    '                        {{ :: \'general.surname_placeholder\' | translate }}" ng-pattern="/^[A-z\\,\\.\\\'\\-æøåöäçÆØÅÖÄÇ\\n\\s\\t]+$/">\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.last_name.$touched && profile.last_name.$error.required">{{ \'general.field_required\' | translate }}</span>\n' +
    '                        <span ng-show="profile.last_name.$touched && profile.last_name.$invalid">{{ \'general.invalid_last_name\' | translate }}</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="form-item js-form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.email\' | translate }}</label>\n' +
    '                    <input type="email" ng-model="profile.email_value" class="form-input" name="email"\n' +
    '                           id="fb-replace-email" required="required"\n' +
    '                           placeholder="{{ :: \'general.email_placeholder\' | translate }}"\n' +
    '                           ng-pattern="/^[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]+\\@+[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]{2,}\\.[A-z\\.\\-\\_æøåöäçÆØÅÖÄÇ\\d]{2,}$/i">\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.email.$touched && profile.email.$error.required">\n' +
    '                                {{ \'general.field_required\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span ng-show="profile.email.$touched && profile.email.$error.pattern">\n' +
    '                                {{ \'general.invalid_email\' | translate }}\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-item js-form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.telephone\' | translate }}</label>\n' +
    '                    <input class="form-input" type="text" ng-model="user.telefone" name="telefone" required="required"\n' +
    '                           id="fb-replace-phone" placeholder="\n' +
    '                        {{ :: \'general.telephone_placeholder\' | translate }}" ng-pattern="/^\\+?[\\d\\-]{7,}$/">\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.telefone.$touched && profile.telefone.$error.required && profile.telefone.$invalid">\n' +
    '                                {{ \'general.field_required\' | translate }}\n' +
    '                        </span>\n' +
    '                        <span ng-show="profile.telefone.$touched && profile.telefone.$invalid">\n' +
    '{{ \'general.invalid_phone\' | translate }}\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-item js-form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.age\' | translate }}</label>\n' +
    '                    <select class="form-select" ng-model="user.age" name="age" required="required" id>\n' +
    '                        <option disabled="" selected="" value="" class="form-select__placeholder"> {{ ::\n' +
    '                            \'general.age_placeholder\' | translate }}\n' +
    '                        </option>\n' +
    '                        <option value="1">0-20</option>\n' +
    '                        <option value="2">21-40</option>\n' +
    '                        <option value="3">41-60</option>\n' +
    '                        <option value="4">60+</option>\n' +
    '                    </select>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.age.$touched && profile.age.$error.required">Dette felt er påkrævet.</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.gender\' | translate }}</label>\n' +
    '                    <select class="form-select" ng-model="user.gender" name="gender" required="required"\n' +
    '                            id="fb-replace-age">\n' +
    '                        <option disabled="" selected="" value="">{{ :: \'general.gender_placeholder\' | translate }}\n' +
    '                        </option>\n' +
    '                        <option value="1">{{ :: \'general.gender_male\' | translate }}</option>\n' +
    '                        <option value="2">{{ :: \'general.gender_female\' | translate }}</option>\n' +
    '                    </select>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.gender.$touched && profile.gender.$error.required">Dette felt er påkrævet.</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-item">\n' +
    '                    <label class="form-label">{{ :: \'general.education\' | translate }}</label>\n' +
    '                    <select class="form-select" ng-model="user.education" name="education" required="required"\n' +
    '                            id="fb-replace-education">\n' +
    '                        <option value="" hidden="">{{ :: \'general.education_placeholder\' | translate }}</option>\n' +
    '                        <option value="1">{{ :: \'general.education_level_1\' | translate }}</option>\n' +
    '                        <option value="2">{{ :: \'general.education_level_2\' | translate }}</option>\n' +
    '                        <option value="3">{{ :: \'general.education_level_3\' | translate }}</option>\n' +
    '                        <option value="4">{{ :: \'general.education_level_4\' | translate }}</option>\n' +
    '                    </select>\n' +
    '                    <div class="form-invalid-message">\n' +
    '                        <span ng-show="profile.education.$touched && profile.education.$error.required">Dette felt er påkrævet.</span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="form-group-terms">\n' +
    '                <label class="form-group-terms-label">\n' +
    '                <input class="form-group-terms-input" ng-model="user.terms" name="terms" type="checkbox"\n' +
    '                       required="required">{{ :: \'general.accept_terms\' | translate }}</label>\n' +
    '            </div>\n' +
    '            <div class="form-invalid-message">\n' +
    '                <span ng-show="profile.terms.$touched && profile.terms.$error.required">Dette felt er påkrævet.</span>\n' +
    '            </div>\n' +
    '\n' +
    '         <!--   <p class="form-text-note">{{ :: \'general.before_continue_text\' | translate }}</p> -->\n' +
    '\n' +
    '            <div class="accordion js-accordion">\n' +
    '                <div class="accordion__item is-selected">\n' +
    '                    <a ng-click="myVar = ! myVar" class="accordion__title js-accordion-toggle pointer">{{ :: \'general.show_more\'\n' +
    '                        | translate}}</a>\n' +
    '                    <div ng-show="myVar">\n' +
    '                         <p ng-bind-html="\'general.show_more_text\'|translate|htmlUnsafe">\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="form-submit-group">\n' +
    '                <input type="submit" class="button-submit" value="{{ :: \'general.see_results\' | translate }}"\n' +
    '                       ng-click="save()">\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>');
}]);
