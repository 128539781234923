angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultButtonReport/personalityResultButtonReport.html',
    '<div class="diploma-teaser text-center" style="background: linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ),url(\'/image/personality/diplom_teaser-{{ locale }}.jpg\')">\n' +
    '    <div class="diploma-teaser-content">\n' +
    '    <h1>{{ diploma_teaser }}</h1>\n' +
    '    <p>&nbsp;</p>\n' +
    '    <a target="_blank" ng-href="/report/{{ stateId}}" class="btn btn-personality-yellow diploma-button">\n' +
    '        {{ \'general.diploma_print\' | translate }}\n' +
    '    </a>\n' +
    '    </div>\n' +
    '    <!--ng-style="{\'background\': \'linear-gradient( rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1) ),url(\\\'/image/personality/diplom_teaser-{{ locale }}.jpg\\\')\'}-->\n' +
    '</div>\n' +
    '');
}]);
