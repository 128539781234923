angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicQuestionTimedTextChoice/nordicQuestionTimedTextChoice.html',
    '<div class="questions js-questions">\n' +
    '    <div class="questions__progressbar">\n' +
    '        <div class="questions__progressbar__section">\n' +
    '            <div class="questions__progressbar__section__item {{ s == activeSubject ? \' is-active\' : \'\' }}" ng-repeat="s in subjects">\n' +
    '                {{ :: s | translate }}\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="questions__progressbar__line">\n' +
    '            <div class="questions__progressbar__line__item" style="width: {{ percentage }}%">\n' +
    '            <span class="questions__progressbar__line__item__number">\n' +
    '                {{ percentage }}%\n' +
    '            </span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="questions__body">\n' +
    '        <div class="questions__title">\n' +
    '            {{ ::question.textId | translate }}\n' +
    '        </div>\n' +
    '        <div class="questions__number">\n' +
    '            {{ :: \'general.questions\' | translate }} {{ questionsPassed }} {{ :: \'general.of\' | translate }} 40\n' +
    '        </div>\n' +
    '        <div class="questions__counter-wrapper">\n' +
    '            <div class="questions__counter">\n' +
    '                <h2 class="questions__counter__number">{{ seconds }}</h2>\n' +
    '                <svg width="45" height="45" xmlns="http://www.w3.org/2000/svg">\n' +
    '                    <g>\n' +
    '                        <title>Layer 1</title>\n' +
    '                        <circle id="circle" class="questions__counter__circle_animation" r="20" cy="22.5" cx="22.5"\n' +
    '                                stroke-width="3" stroke="#FFD745" fill="none"\n' +
    '                                style="stroke-dashoffset: {{ offset }}px;"></circle>\n' +
    '                    </g>\n' +
    '                </svg>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="questions__answers">\n' +
    '            <div class="questions__answers__item" ng-repeat="choice in question.choices">\n' +
    '                <span class="questions__answers__item__text" ng-click="select(choice)">\n' +
    '                    {{ :: choice.textId | translate }}\n' +
    '                </span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
