angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/component/sections/sections.html',
    '<div class="" style="margin-bottom: 10px">\n' +
    '    <ul class="hidden-xs nav nav-pills nav-justified">\n' +
    '        <li role="presentation"\n' +
    '            class="disabled"\n' +
    '            ng-repeat="section in sections"\n' +
    '            ng-class="{\'active\' : section.id == active}">\n' +
    '                <a style="cursor: default">\n' +
    '                    {{ section.textId | translate }}\n' +
    '                </a>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '    <ul class="hidden-sm hidden-md hidden-lg nav nav-pills nav-pills-xs">\n' +
    '        <li role="presentation"\n' +
    '            class="disabled"\n' +
    '            ng-repeat="section in sections"\n' +
    '            ng-class="{\'active\' : section.id == active}">\n' +
    '            <a class="small" style="cursor: default; padding: 10px 10px;">\n' +
    '                {{ section.textId | translate }}\n' +
    '            </a>\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '</div>\n' +
    '');
}]);
