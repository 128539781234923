angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultShopJob/personalityResultShopJob.html',
    '<div class="result-shop vertical-center">\n' +
    '    <div class="col-xs-12 col-md-6 text-center">\n' +
    '        <p>{{ \'general.result_shop_job_text\' | translate }}</p>\n' +
    '        <a href="{{ \'general.result_shop_job_link\'| translate }}" target="_blank" class="btn btn-lg btn-primary">{{ \'general.result_shop_job_button\'| translate }}</a>\n' +
    '    </div>\n' +
    '    <div class="col-xs-12 col-md-6">\n' +
    '        <a href="{{ \'general.result_shop_job_link\'| translate }}" target="_blank">\n' +
    '            <img src="image/personality/job_shop_{{ locale }}.jpg">\n' +
    '        </a>\n' +
    '    </div>\n' +
    '    <br style="clear:both">\n' +
    '</div>\n' +
    '');
}]);
