angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultButtonShareFacebook/personalityResultButtonShareFacebook.html',
    '<div class="result-facebook-share text-center">\n' +
    '    <h1>{{ \'general.share_facebook_teaser\' | translate }}</h1>\n' +
    '    <button class="btn btn-facebook" ng-click="share()">\n' +
    '        <i class="fa fa-facebook-square"></i> {{ \'general.share_facebook_button\' | translate }}\n' +
    '    </button>\n' +
    '</div>\n' +
    '');
}]);
