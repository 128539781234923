angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultButtonRestart/personalityResultButtonRestart.html',
    ' <div class="quiz-restart text-center">\n' +
    '    <button\n' +
    '            ng-href="{{ match.character.linkId | translate }}"\n' +
    '            target="_blank"\n' +
    '            class="btn btn-personality-red"\n' +
    '            onclick="document.location=\'/clear\';"\n' +
    '    >\n' +
    '        <i class="fa fa-repeat"></i> {{ \'general.restart_quiz\' | translate }}\n' +
    '    </button>\n' +
    '</div>\n' +
    '');
}]);
