angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/iq/component/iqQuestionTimedTextChoice/iqQuestionTimedTextChoice.html',
    '<div>\n' +
    '    <div class="hidden-xs iq-timed-text-choice-ticker">\n' +
    '        <span>{{ seconds }}</span>\n' +
    '    </div>\n' +
    '    <div class="hidden-sm hidden-md hidden-lg iq-timed-text-choice-ticker iq-xs">\n' +
    '        <span>{{ seconds }}</span>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading"><strong>{{ ::question.textId | translate }}</strong></div>\n' +
    '        <div class="list-group">\n' +
    '            <a class="list-group-item iq-timed-text-choice-item"\n' +
    '               ng-repeat="choice in question.choices"\n' +
    '               ng-class="{\'active\': isSelected(choice), \'disabled\': isTimedOut()}"\n' +
    '               ng-click="select(choice)">\n' +
    '                {{ ::choice.textId | translate }}\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
