angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('section/quiz/template/debugger.html',
    '<div ng-controller="QuizPageDebugController">\n' +
    '\n' +
    '    <h2>Debugging</h2>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-6">\n' +
    '            <div class="panel panel-default">\n' +
    '                <div class="panel-heading">\n' +
    '                    Pages\n' +
    '                    <div class="pull-right">\n' +
    '                        <a class="btn btn-xs btn-default" ui-sref="quizPage({pageId: page.prev.id})" ng-show="page.prev">Previous</a>\n' +
    '                        <a class="btn btn-xs btn-default btn-disable" ng-show="!page.prev">Previous</a>\n' +
    '\n' +
    '                        <a class="btn btn-xs btn-default" ui-sref="quizPage({pageId: page.next.id})" ng-show="page.next">Next</a>\n' +
    '                        <a class="btn btn-xs btn-default btn-disable" ng-show="!page.next">Next</a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <ul class="list-group debug-limit-height">\n' +
    '                    <li class="list-group-item" ng-repeat="p in quiz.pages.getAll()" ng-class="{\'active\' : p.id == page.id}">\n' +
    '                        <span class="pull-right">\n' +
    '                            <span ng-show="p.isCompleted()" class="glyphicon glyphicon-ok-sign"></span>\n' +
    '                            <span ng-hide="p.isCompleted()" class="glyphicon glyphicon-remove-sign"></span>\n' +
    '                        </span>\n' +
    '                        <a ui-sref="quizPage({pageId: p.id})">{{ ::p.id }}</a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '            <div class="panel panel-default">\n' +
    '                <div class="panel-heading">\n' +
    '                    State\n' +
    '                    <div class="pull-right">\n' +
    '                        <button class="btn btn-xs btn-warning" ng-click="clearState()">Clear State</button>\n' +
    '                        <button class="btn btn-xs btn-danger"  ng-click="restartQuiz()">Restart Quiz</button>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '                <div class="panel-body debug-limit-height">\n' +
    '                    <pre style="margin: -15px; border: 0; font-size: 10px; background-color: #fff">{{ getState()|json:4 }}</pre>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading">\n' +
    '            Translation Messages\n' +
    '            <div class="pull-right">\n' +
    '                <button ng-click="dumpTranslation(translation.getTranslation(\'en\'))"class="btn btn-xs btn-default">Dump</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="debug-limit-height">\n' +
    '            <table class="table table-striped table-condensed">\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, value) in translation.getMessages() | orderBy:-key">\n' +
    '                        <td><small><code>{{ key }}</code></small></td>\n' +
    '                        <td><small>{{ value }}</small></td>\n' +
    '                        <td>\n' +
    '                            <span ng-show="translation.hasDeclaration(key)">y</span>\n' +
    '                            <span ng-hide="translation.hasDeclaration(key)">n</span>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading">\n' +
    '            Declared Messages\n' +
    '        </div>\n' +
    '        <div class="debug-limit-height">\n' +
    '            <table class="table table-striped table-condensed">\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="key in translation.getDeclarations() | orderBy:-key">\n' +
    '                        <td><small><code>{{ key }}</code></small></td>\n' +
    '                        <td><small>{{ translation.getMessage(key) }}</small></td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    -->\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
