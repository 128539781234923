angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicShareFacebookSectionResult/nordicShareFacebookSectionResult.html',
    '<div>\n' +
    '    <div>\n' +
    '        <button class="btn btn-lg btn-primary btn-block nordic-button-grey" ng-click="share()">\n' +
    '            <i class="fa fa-facebook-square"></i> {{ \'general.share_facebook_button\' | translate }}\n' +
    '        </button>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
