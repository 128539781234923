angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/quiz/component/progressSaveButton/progressSaveButton.html',
    '<div>\n' +
    '        <div ng-show="state == \'pending\'">\n' +
    '            <button class="btn btn-lg btn-primary btn-block iq-button-grey" ng-disabled="!canSave()" ng-click="save()">\n' +
    '                <i class="fa fa-envelope"></i> {{ \'general.save_progress_pending_button\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '        <div ng-show="state == \'saving\'">\n' +
    '            <button class="btn btn-lg btn-default btn-block iq-button-grey" ng-disabled="true">\n' +
    '                <i class="fa fa-circle-o-notch fa-spin"></i>\n' +
    '            </button>\n' +
    '        </div>\n' +
    '        <div ng-show="state == \'error\'">\n' +
    '            <button class="btn btn-lg btn-primary btn-block iq-button-grey" ng-disabled="!canSave()" ng-click="save()">\n' +
    '                <i class="fa fa-envelope"></i> {{ \'general.save_progress_error_button\' | translate }}\n' +
    '            </button>\n' +
    '        </div>\n' +
    '        <div ng-show="state == \'saved\'">\n' +
    '            <button class="btn btn-lg btn-success btn-block iq-button-grey">\n' +
    '                <i class="fa fa-envelope"></i> <a ng-href="{{ \'general.save_progress_return_url\' | translate }}">{{ \'general.save_progress_return_button\' | translate }}</a>\n' +
    '            </button>\n' +
    '        </div>\n' +
    '</div>\n' +
    '');
}]);
