angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityQuestion/personalityQuestion.html',
    '<div>\n' +
    '    <div class="text-center">\n' +
    '        <h2 class="personality-question">{{ ::question.getTextId() | translate }}</h2>\n' +
    '    </div>\n' +
    '    <ul class="personality-question-answers">\n' +
    '        <li class="personality-question-answer"\n' +
    '            ng-repeat="choice in question.getChoices()"\n' +
    '            ng-class="{\'active\': isSelected(choice)}"\n' +
    '            ng-click="select(choice)">\n' +
    '            {{ choice.textId | translate }}\n' +
    '        </li>\n' +
    '    </ul>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
