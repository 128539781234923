angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicCategoryItem/nordicCategoryItem.html',
    '<a class="category__item" ng-click="toCategory()">\n' +
    '    <div class="category__item__icon">\n' +
    '        <div class="{{ iconClass }}"></div>\n' +
    '    </div>\n' +
    '    <div class="category__item__text">\n' +
    '        <div class="category__item__title">{{ :: title | translate }}</div>\n' +
    '        <div class="category__item__description">{{ :: description | translate }}</div>\n' +
    '    </div>\n' +
    '</a>');
}]);
