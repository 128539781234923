angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/nordic/component/nordicProgressBar/nordicProgressBar.html',
    '<div class="questions__progressbar">\n' +
    '    <div class="questions__progressbar__section">\n' +
    '        <div class="questions__progressbar__section__item" ng-repeat="s in subjects">\n' +
    '            {{ :: s | translate }}\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="questions__progressbar__line">\n' +
    '        <div class="questions__progressbar__line__item" style="width: {{ percentage }}%">\n' +
    '            <span class="questions__progressbar__line__item__number">\n' +
    '                {{ percentage }}%\n' +
    '            </span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
