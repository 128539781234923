angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/personality/component/personalityResultCharacter/personalityResultCharacter.html',
    '<div>\n' +
    '    <div >\n' +
    '        <h2 class="text-center">{{ \'general.result\' | translate }}</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div>\n' +
    '        <div class="text-center">\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="row personality-result-top">\n' +
    '            <div class="col-xs-6 text-center personality-lifetime">\n' +
    '                <p>{{ \'general.matched_character_lifetime\'| translate }}</p>\n' +
    '                <h1 style="color: #7D734F">{{ match.character.born}}-{{ match.character.died}}</h1>\n' +
    '            </div>\n' +
    '            <div class="col-xs-6  personality-match text-center" style="background-color: #222222; height: 140px;  padding: 15px">\n' +
    '                <h1 style="color: #F9D800">{{ match.percent }}%</h1>\n' +
    '                <p style="color: #FFFFFF">{{ \'general.matched_character_percent\'| translate }}</p>\n' +
    '            </div>\n' +
    '            <img class="personality-image" ng-src="{{ match.character.image }}">\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="personality-character text-center" style="margin-bottom: 15px">\n' +
    '        <h1>\n' +
    '            {{ match.character.nameId | translate }}\n' +
    '        </h1>\n' +
    '        <hr style="width: 40%"/>\n' +
    '\n' +
    '        <div class="personality-text personality-character-intro">\n' +
    '            <p><strong>{{ match.character.shorttextId | translate}}</strong></p>\n' +
    '            <p>\n' +
    '                <strong>{{ \'general.diploma_known_for\' | translate }}</strong>\n' +
    '                {{ match.character.knownforId | translate}}\n' +
    '            </p>\n' +
    '        </div>\n' +
    '        <div\n' +
    '                class="personality-text"\n' +
    '                ng-bind-html="match.character.textId | translate | markdown"\n' +
    '        ></div>\n' +
    '        <!--<a ng-href="{{ match.character.linkId | translate }}" target="_blank" class="btn btn-personality-red">-->\n' +
    '            <!--{{ \'general.character_read_more\'| translate }}-->\n' +
    '        <!--</a>-->\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
