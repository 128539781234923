angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/component/columns/columns.html',
    '<div class="row">\n' +
    '    <div ng-repeat="column in columns" ng-class="class(column.width)">\n' +
    '        <div ng-repeat="c in column.components">\n' +
    '            <div component="c"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
