angular.module('app').run(['$templateCache', function($templateCache) {
  $templateCache.put('common/iq/component/iqQuestionMemorySequence/iqQuestionMemorySequence.html',
    '<div style="" class="iq-memory-sequence">\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-4 col-md-offset-4">\n' +
    '            <div ng-hide="running">\n' +
    '                <button class="btn btn-primary btn-block iq-memory-sequence-button"\n' +
    '                    ng-click="start()"\n' +
    '                    ng-disabled="running">\n' +
    '                    <i class="fa fa-play-circle-o"></i>\n' +
    '                </button>\n' +
    '            </div>\n' +
    '            <div ng-show="running" class="iq-memory-sequence-display">\n' +
    '                <span>{{ currentCharacter }}</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <input class="form-control input-lg iq-memory-sequence-input"\n' +
    '        type="text"\n' +
    '        ng-model="answer"\n' +
    '        ng-trim="false"\n' +
    '        ng-disabled="!answerEnabled"\n' +
    '        memory-sequence-input/>\n' +
    '\n' +
    '    <div class="row" ng-show="debug">\n' +
    '        <div class="col-md-6">\n' +
    '            <button class="btn btn-xs btn-block btn-success" style="margin-top: 10px" ng-click="debugAnswerCorrectly()">DEBUG Answer Correctly</button>\n' +
    '        </div>\n' +
    '        <div class="col-md-6">\n' +
    '            <button class="btn btn-xs btn-block btn-danger" style="margin-top: 10px" ng-click="debugAnswerWrongly()">DEBUG Answer Wrongly</button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
